import { actions, selectors, } from '@ezugi/bootstrap';
import {
  filter, mapObjIndexed, pick, prop, values, isEmpty,
} from 'ramda';
import * as BET_TYPES from '../../../../constants/betTypes';


import { betsHistorySelector, currentBetsSelector, } from '../../../selectors/bets';
import { getTotalBet, } from '.';

const MAIN_BETS = Object.values(BET_TYPES.MAIN_BETS);
const SIDEBETS = Object.values(BET_TYPES.SIDEBETS);

const { socketActions, notificationActions, betActions, } = actions;
const { userSelector, tableIdSelector, roundIdSelector, gameSelector, } = selectors;

export const buildParams = (bets, state, seed) => {
  const user = userSelector(state);

  return {
    ClientId: user.clientId,
    ClientIP: user.clientIp,
    CurrentPlayerToken: user.currentPlayerToken,
    MessageType: 'PlaceBet',
    destination: 'player',
    gameType: gameSelector(state).gameId,
    TableId: tableIdSelector(state).toString(),
    roundId: roundIdSelector(state),
    BetsList: bets,
    ...seed,
  };
};

const keepValidBets = filter(prop('valid'));

export const createBetsList = (bets) => {
  // Send bets
  const seed = mapObjIndexed(({ value, }) => value, bets);

  const betsList = pick(values(MAIN_BETS), seed);
  const sidebets = pick(values(SIDEBETS), seed);

  return {
    betsList,
    sideBets: sidebets,
  };
};

export const createPlaceBetRequestPayload = (state) => {
  const currentBetsList = currentBetsSelector(state);
  const betHistory = betsHistorySelector(state);
  const _actions = [];

  // used in case of reconnection, avoids resending bets
  if (betHistory.length <= 1) return actions;

  const currentBets = values(currentBetsList);

  if (currentBets.length) {
    const validBetsList = keepValidBets(currentBetsList);
    const totalBet = getTotalBet(validBetsList);

    if (currentBets.length > values(validBetsList).length) {
      // Remove invalid bets and update `TotalBet` and `BetHistory`
      _actions.push(notificationActions.notification.add({ message: 'notifications.bet_below_min_limits_removed', }));
      _actions.push(betActions.history.apply({ current: validBetsList, }));
      _actions.push(betActions.totalBet.set({ value: totalBet, }));
    }

    if (!isEmpty(validBetsList)) {
      // Cache valid bets for `Rebet`
      _actions.push(betActions.bet.cache({ current: validBetsList, totalBet, }));

      // Send bets
      const { betsList, sideBets, } = createBetsList(validBetsList);

      const params = buildParams(betsList, state, {
        SideBets: sideBets,
      });
      _actions.push(socketActions.socket.send(params));
    }
  }

  return _actions;
};
