import { flatMap, pluck, } from 'rxjs/operators';
import { of, } from 'rxjs';
import { combineEpics, ofType, } from 'redux-observable';
import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import myBetsActions from '../../actions/myBets';
import { formatPayout, } from '../payouts/utils';

import { numberOfCardsSelector, } from '../../selectors/cards';
import { computeMyBetsSelector, } from '../../selectors/bets';

import { BETS_ORDERS, } from '../../../constants/betTypes';

const {
  formatSelector,
} = bootstrapSelectors;
const {
  myBets,
} = myBetsActions;

const myBetsCacheEpic = (action$, state$) => action$.pipe(
  ofType(myBets.cache),
  pluck('payload'),
  flatMap(() => {
    const myBetsObj = computeMyBetsSelector(state$.value);
    const nbCards = numberOfCardsSelector(state$.value);
    return of(myBets.push({ [nbCards + 1]: myBetsObj, }));
  })
);

const myBetsComputeEpic = (action$, state$) => action$.pipe(
  ofType(myBets.compute),
  pluck('payload'),
  flatMap((betsPayload = []) => {
    const { payoutFormat, } = formatSelector(state$.value);
    const myBetsObj = betsPayload.reduce((acc, { betAmount, step, type, status, approved, ...values }) => {
      acc[step + 1] = { ...acc[step + 1],
        [type]: {
          ...values,
          payout: formatPayout(values.payout, payoutFormat),
          name: type,
          value: betAmount,
          valid: approved || status === 'APPROVED',
          index: BETS_ORDERS[type],
        }, };
      return acc;
    }, {});

    return of(myBets.push(myBetsObj));
  })
);

export default combineEpics(
  myBetsCacheEpic,
  myBetsComputeEpic,
);
