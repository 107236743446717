import styled, { css, } from 'react-emotion';

export const Container = styled.div`
  text-transform: uppercase;
  white-space: nowrap;
  writing-mode: vertical-lr;
  user-select: none;
`;

export const parentClassName = css`
  align-items: center;
  width: 22px;
`;
